import { action, makeObservable, observable } from "mobx"
import { ErrorHandler } from "../../error/ErrorHandler"
import { TableConfig } from "../../components/DataTable/types/TableConfig"
import { ViewModel } from "../ViewModel"
import { UploadTransactionsUseCase } from "../../../domain/useCases/transactions/uploadTransactions"
import { UploadProgressStatus } from "../../../domain/enum/uploadProgressStatus.enum"
import { Filter } from "../../../domain/types/transactions/Filter"
import { Transaction } from "../../../domain/entities/Transaction"
import { GetTransactionsUseCase } from "../../../domain/useCases/transactions/getTransactions"
import { DownloadTransactionsUseCase } from "../../../domain/useCases/transactions/downloadTransactions"
import { FileService } from "../../../domain/services/FileService"
import { UpdateTransactionUseCase } from "../../../domain/useCases/transactions/updateTransaction"
import { GetMicrositesUseCase } from "../../../domain/useCases/microsites/getMicrosites"
import { Microsite } from "../../../domain/entities/Microsite"
import { GetSellerServicesUseCase } from "../../../domain/useCases/sellersServices/getSellersServices"
import { SellerService } from "../../../domain/entities/SellerService"
import { GetSellerServiceDetailUseCase } from "../../../domain/useCases/sellersServices/getSellerServiceDetail"
import { GetVoucherRulesUseCase } from "../../../domain/useCases/vouchers/getVoucherRules"
import { VoucherRules } from "../../../data/dto/VoucherRules.dto"
import { CreateTransactionUseCase } from "../../../domain/useCases/transactions/createTransaction"
import { CreateTransactionDTO } from "../../../data/dto/CreateTransaction.dto"
import { SellerServiceDetail } from "../../../domain/entities/SellerServiceDetail"

interface Props {
	ErrorHandler: ErrorHandler
	UploadTransactionsUseCase: UploadTransactionsUseCase
	GetTransactionsUseCase: GetTransactionsUseCase
	DownloadTransactionsUseCase: DownloadTransactionsUseCase
	UpdateTransactionUseCase: UpdateTransactionUseCase
	GetMicrositesUseCase: GetMicrositesUseCase
	GetSellerServicesUseCase: GetSellerServicesUseCase
	GetSellerServiceDetailUseCase: GetSellerServiceDetailUseCase
	GetVoucherRulesUseCase: GetVoucherRulesUseCase
	CreateTransactionUseCase: CreateTransactionUseCase
}
type ErrorType = "header" | "detail" | undefined
type Error = { message: string; type: ErrorType }

export class TransactionsViewModel extends ViewModel {
	private _errorHandler
	private _uploadTransactionsUseCase: UploadTransactionsUseCase
	private _createTransactionUseCase: CreateTransactionUseCase
	private _updateTransactionUseCase: UpdateTransactionUseCase
	private _getTransactionsUseCase: GetTransactionsUseCase
	private _downloadTransactionsUseCase: DownloadTransactionsUseCase
	private _getMicrositesUseCase: GetMicrositesUseCase
	private _getServicesUseCase: GetSellerServicesUseCase
	private _getServiceDetailUseCase: GetSellerServiceDetailUseCase
	private _getVoucherRulesUseCase: GetVoucherRulesUseCase
	isFetching: boolean = false
	isLoading: boolean = false
	isLoadingDetail: boolean = false
	progressData: { active: boolean; file?: File; message: string; status?: UploadProgressStatus } = {
		active: false,
		message: "",
		status: UploadProgressStatus.LOADING
	}
	uploadingFile: boolean = false
	searchedTransactions: Transaction[] = []
	error: Error = { message: "", type: undefined }
	editMode: boolean = false
	transactions: Transaction[] = []
	searchValue: string = ""
	tableConfig: TableConfig = {
		pageSize: 20,
		sort: { order: "descend", field: "id" }
	}
	tipTableFilters = {}
	microsites: Microsite[] | null = null
	services: SellerService[] | null = null
	selectedService: SellerServiceDetail | null = null
	voucherRules: VoucherRules[] | null = null

	constructor({
		ErrorHandler,
		UploadTransactionsUseCase,
		GetTransactionsUseCase,
		DownloadTransactionsUseCase,
		UpdateTransactionUseCase,
		GetMicrositesUseCase,
		GetSellerServicesUseCase,
		GetSellerServiceDetailUseCase,
		GetVoucherRulesUseCase,
		CreateTransactionUseCase
	}: Props) {
		super()
		makeObservable(this, {
			uploadingFile: observable,
			setUploadingFile: action,
			progressData: observable,
			setProgressData: action,
			isFetching: observable,
			isLoading: observable,
			isLoadingDetail: observable,
			transactions: observable,
			microsites: observable,
			services: observable,
			selectedService: observable,
			voucherRules: observable,
			setTransactions: action,
			searchedTransactions: observable,
			setSearchedTransactions: action,
			setMicrosites: action,
			setServices: action,
			setSelectedService: action,
			setLoading: action,
			setLoadingDetail: action,
			cleanPagination: action,
			setVoucherRules: action
		})
		this._errorHandler = ErrorHandler
		this._uploadTransactionsUseCase = UploadTransactionsUseCase
		this._getTransactionsUseCase = GetTransactionsUseCase
		this._downloadTransactionsUseCase = DownloadTransactionsUseCase
		this._updateTransactionUseCase = UpdateTransactionUseCase
		this._getMicrositesUseCase = GetMicrositesUseCase
		this._getServicesUseCase = GetSellerServicesUseCase
		this._getServiceDetailUseCase = GetSellerServiceDetailUseCase
		this._getVoucherRulesUseCase = GetVoucherRulesUseCase
		this._createTransactionUseCase = CreateTransactionUseCase
		this.fetchTransactions()
	}

	public async uploadTransactions(file: File) {
		try {
			this.setUploadingFile(true)
			const frmData = new FormData()
			frmData.append("file", file)
			const response = await this._uploadTransactionsUseCase.exec(frmData)
			return response
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setUploadingFile(false)
		}
	}

	public async createTransaction(transaction: CreateTransactionDTO) {
		try {
			const transactionId = await this._createTransactionUseCase.exec(transaction)
			this.fetchTransactions()
			return transactionId
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async updateTransaction(transaction: Transaction, sendEmails: boolean) {
		try {
			await this._updateTransactionUseCase.exec({ transaction, sendEmails: sendEmails ?? false })
			this.fetchTransactions()
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public closeProgress() {
		this.setProgressData({ active: false, message: "", status: UploadProgressStatus.LOADING })
	}

	public async fetchTransactions(filter?: Filter) {
		try {
			this.setLoading(true)
			const transactions = await this._getTransactionsUseCase.exec({
				pagination: 0,
				limit: this.limit,
				filter
			})
			this.setTransactions(transactions)
			this.cleanPagination()
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async downloadTransactions(filter?: Filter) {
		try {
			this.setLoading(true)
			const file = await this._downloadTransactionsUseCase.exec({
				pagination: 0,
				limit: 5000,
				filter
			})
			const fileService = new FileService()
			const dateString = new Date().toISOString().split("T")[0]
			fileService.dowloadFile(file, `Transactions_${dateString}.csv`)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async fetchMicrositesAndServices() {
		try {
			this.setLoading(true)
			const microsites = await this._getMicrositesUseCase.exec()
			const services = await this._getServicesUseCase.exec({ pagination: 0, limit: 1000, status: "active" })
			this.setMicrosites(microsites)
			this.setServices(services)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async fetchServiceDetail(serviceId: number, filter?: { micrositeId?: number; country?: string }) {
		try {
			this.setLoadingDetail(true)
			const serviceDetail = await this._getServiceDetailUseCase.exec(serviceId.toString())
			const voucherRules = await this._getVoucherRulesUseCase.exec({ serviceId, ...filter })
			this.setVoucherRules(voucherRules)
			this.setSelectedService(serviceDetail)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoadingDetail(false)
		}
	}

	public async fetchVoucherRules(filter?: { serviceId?: number; micrositeId?: number; country?: string }) {
		try {
			this.setLoadingDetail(true)
			const voucherRules = await this._getVoucherRulesUseCase.exec(filter ?? {})
			this.setVoucherRules(voucherRules)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoadingDetail(false)
		}
	}

	public cleanPagination() {
		this.pagination = 0
		this.setHasNextPage(true)
	}

	public async fetchPaginatedTransactions(filter?: Filter) {
		this.setIsFetching(true)
		try {
			const transactions = await this._getTransactionsUseCase.exec({
				pagination: this.pagination,
				limit: this.limit,
				filter
			})
			if (transactions.length > 0) {
				const combinedTransactions = [...transactions, ...this.transactions]
				this.setTransactions(combinedTransactions)
			}
			this.setHasNextPage(transactions.length > 0)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setIsFetching(false)
		}
	}

	// ACTIONS
	setTransactions(transactions: Transaction[]) {
		this.transactions = transactions
		this.setSearchedTransactions(transactions)
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading
	}

	setLoadingDetail(isLoadingDetail: boolean) {
		this.isLoadingDetail = isLoadingDetail
	}

	setUploadingFile(uploadingFile: boolean) {
		this.uploadingFile = uploadingFile
	}

	setIsFetching(isFetching: boolean) {
		this.isFetching = isFetching
	}

	setProgressData(progressData: typeof this.progressData) {
		this.progressData = progressData
	}

	setEditMode(editMode: boolean) {
		this.editMode = editMode
	}

	setSearchedTransactions(transactions: Transaction[]) {
		this.searchedTransactions = transactions
	}

	setError(message: string, type: ErrorType) {
		this.error = { message, type }
	}

	setTableConfig(config: Partial<typeof this.tableConfig>) {
		this.tableConfig = { ...this.tableConfig, ...config }
	}

	setMicrosites(microsites: Microsite[]) {
		this.microsites = microsites
	}

	setServices(services: SellerService[]) {
		this.services = services
	}
	setSelectedService(service: SellerServiceDetail) {
		this.selectedService = service
	}
	setVoucherRules(voucherRules: VoucherRules[]) {
		this.voucherRules = voucherRules
	}
}
