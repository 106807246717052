import { Col, Modal, Row, Typography } from "antd"
import { createRef, useEffect, useRef, useState } from "react"
import { ServiceSessionModalProps } from "./interface/ServiceSessionModalProps"
import MainForm, { MainFormRef } from "../../../components/Form/MainForm"
import { observer } from "mobx-react"
import { ServiceSessionForm } from "./ServiceSessionForm"
import { useTranslation } from "react-i18next"
import { FormField } from "../../../components/Form/FormField"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import Divider from "../../../styledComponents/CustomDivider/Divider"

const ServiceSessionModal = ({ data, currencies, visible, onConfirm, onCancel }: ServiceSessionModalProps) => {
	const { t } = useTranslation("services", { keyPrefix: "form.sessionsInfo.sessionModal" })
	const initialModalData = {
		description: data?.description,
		description_en: data?.description_en,
		description_ca: data?.description_ca,
		subtitle: data?.subtitle,
		subtitle_en: data?.subtitle_en,
		subtitle_ca: data?.subtitle_ca,
		editting: data?.editting,
		id: data?.id,
		key: data?.key,
		multicurrency: data?.multicurrency,
		position: data?.position,
		outprice: data?.outprice || 0,
		price: data?.price || 0,
		title: data?.title,
		title_en: data?.title_en,
		title_ca: data?.title_ca,
		updated: data?.updated
	}
	const handleCancel = () => {
		onCancel && onCancel()
	}
	const [modalData, setModalData] = useState<any>(initialModalData)
	const formRef = createRef<MainFormRef>()
	const currencyFormRefs = currencies.map(c => createRef<MainFormRef>(), createRef<MainFormRef>())
	useEffect(() => {
		!data?.updated ? setModalData(undefined) : setModalData(initialModalData)
	}, [visible])

	const verifyIfMulticurrencies = (data: any): boolean => {
		for (const currency of data) {
			if (currency.price !== undefined || currency.outprice !== undefined) {
				return true
			}
		}
		return false
	}

	return (
		<Modal
			title={
				<div style={{ position: "relative", bottom: 24 }}>
					<Typography.Title level={5}>{t("modalTitle")}</Typography.Title>
					<Divider style={{ marginBottom: 0 }} />
				</div>
			}
			centered
			style={{ overflowY: "auto", padding: "60px 0px" }}
			open={visible}
			onOk={async () => {
				if (modalData && modalData.multicurrency && verifyIfMulticurrencies(modalData.multicurrency)) {
					const multicurrencys = modalData
					multicurrencys.multicurrency = multicurrencys.multicurrency.filter(
						(value: { price: number | undefined }) => value.price !== undefined && value.price !== null
					)
					multicurrencys["price"] = modalData.multicurrency[0]?.price ?? 0
					multicurrencys["outprice"] = modalData.multicurrency[0]?.outprice ?? 0
					multicurrencys["sellerPrice"] = modalData.multicurrency[0]?.sellerPrice ?? 0
					setModalData(multicurrencys)
				}
				await formRef.current?.validateFields()
				await Promise.all(currencyFormRefs.map(f => f.current?.validateFields()))
				onConfirm && onConfirm(modalData)
			}}
			onCancel={() => handleCancel()}
			width={1000}
			okText={t("confirm")}
			cancelText={t("cancel")}
		>
			<MainForm
				initialValues={modalData}
				hideFormButtons
				onChangeFormValues={formData => {
					if (JSON.stringify(modalData) !== JSON.stringify(formData)) {
						setModalData({
							...modalData,
							...formData
						})
					}
				}}
				ref={formRef}
			>
				<Row gutter={[24, 0]}>
					<Col xl={8} md={12} xs={24}>
						<FormField.Input key={"title"} label={t("title")} name={"title"} mandatory />
					</Col>
					<Col xl={8} md={12} xs={24}>
						<FormField.Input key={"title_en"} label={`${t("title")} (EN)`} name={"title_en"} mandatory />
					</Col>
					<Col xl={8} md={12} xs={24}>
						<FormField.Input key={"title_ca"} label={`${t("title")} (CA)`} name={"title_ca"} />
					</Col>
					<Col xl={8} md={12} xs={24}>
						<FormField.Input
							key={"position"}
							label={t("position")}
							name={"position"}
							inputType={InputType.NUMBER}
						/>
					</Col>
				</Row>
				{currencies.map((currency, index) => {
					return (
						<ServiceSessionForm
							// mandatory={modalData ? !modalData?.multicurrency?.some((currency: ServiceSession) => currency.price !== undefined) : true}
							formRef={currencyFormRefs[index]}
							key={index}
							data={
								modalData && modalData.multicurrency
									? modalData.multicurrency.find(
											(value: { currencyId: number }) => String(value.currencyId) === currency.id
									  )
									: undefined
							}
							currency={currency}
							onChangeText={formData => {
								if (modalData && modalData.multicurrency) {
									const multicurrencys = modalData.multicurrency
									const position = multicurrencys.findIndex(
										(value: { currencyId: number }) =>
											value.currencyId === Number(formData.currencyId)
									)
									if (position >= 0) {
										multicurrencys[position] = formData
									} else {
										multicurrencys[multicurrencys.length] = formData
									}
									setModalData({ ...modalData, multicurrency: multicurrencys })
								} else {
									const newMultiCurrency = { ...modalData, multicurrency: [formData] }
									setModalData(newMultiCurrency)
								}
							}}
						/>
					)
				})}
				<Row gutter={[24, 0]}>
					<Col xl={12} md={12} xs={24}>
						<FormField.Input key={"subtitle"} label={t("subtitle")} name={"subtitle"} />
					</Col>
					<Col xl={12} md={12} xs={24}>
						<FormField.Input key={"subtitle_en"} label={`${t("subtitle")} (EN)`} name={"subtitle_en"} />
					</Col>
					<Col xl={12} md={12} xs={24}>
						<FormField.Input key={"subtitle_ca"} label={`${t("subtitle")} (CA)`} name={"subtitle_ca"} />
					</Col>
				</Row>
				<FormField.Input
					key={"description"}
					label={t("description")}
					name={"description"}
					inputType={InputType.TEXTAREA}
				/>
				<FormField.Input
					key={"description_en"}
					label={`${t("description")} (EN)`}
					name={"description_en"}
					inputType={InputType.TEXTAREA}
				/>
				<FormField.Input
					key={"description_ca"}
					label={`${t("description")} (CA)`}
					name={"description_ca"}
					inputType={InputType.TEXTAREA}
				/>
			</MainForm>
		</Modal>
	)
}

export default observer(ServiceSessionModal)
