import MainForm, { MainFormRef } from "../../components/Form/MainForm"
import { createRef, useEffect, useMemo, useState } from "react"
import { Button, Col, Row } from "antd"
import { Seller } from "../../../domain/entities/Seller"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { SellerServicesViewModel } from "./SellerServicesViewModel"
import { observer } from "mobx-react"
import { useLocation, useNavigate } from "react-router-dom"
import ServiceInformationFormSection from "./components/ServiceInformationFormSection"
import DataTable from "../../components/DataTable/DataTable"
import TABLE_COLUMNS from "./const/SESSIONS_TABLE_COLUMNS"
import { ServiceSession } from "../../../domain/entities/ServiceSesssion"
import { DeleteFilled, EditFilled } from "@ant-design/icons"
import { useConfirmModal } from "../../service/confirmModal"
import { toJS } from "mobx"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import { ServiceConfigurationFormSection } from "./components/ServiceConfigurationFormSection"
import ServiceSessionModal from "./components/ServiceSessionModal"
import { useTranslation } from "react-i18next"
import { SellerServiceDetail } from "../../../domain/entities/SellerServiceDetail"
import { useToastMessage } from "../../service/toastMessage"
import ServiceAvailabilityFormSection, { ServiceAvailabilityFormRef } from "./components/ServiceAvailabilityFormSection"
import DataTableRowsSkeleton from "../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import { FormField } from "../../components/Form/FormField"
import { Card } from "../../styledComponents/CustomCard/Card"
import { ServiceStatus } from "../../../domain/enum/serviceStatus.enum"
import { ServiceOption } from "../../service/ServiceOption"
import { AuthStore } from "../../stores/AuthStore"
import Switch from "../../components/Switch/Switch"
import { SellerOffice } from "../../../domain/entities/SellerOffice"
import { InputType } from "../../components/TextInput/const/InputType.enum"
import { ServiceOffice } from "../../../domain/entities/ServiceOffice"
import { ReservationLimitInterval } from "../../../domain/enum/reservationLimitInterval.enum"
import dayjs from "dayjs"

export type EditableServiceSession = ServiceSession & { editting?: boolean }

const SellerServicesFormView = () => {
	const { t } = useTranslation("services", { keyPrefix: "form" })
	const { t: tToast } = useTranslation("toast")
	const navigate = useNavigate()
	const { context: toastContext } = useToastMessage()
	const { showConfirm, context: modalContext } = useConfirmModal()
	const locationState: { service: SellerServiceDetail; option: ServiceOption } = useLocation().state
	const formRef = createRef<MainFormRef>()
	const serviceAvailavitityFormRef = createRef<ServiceAvailabilityFormRef>()
	const viewModel = useContainerInjection<SellerServicesViewModel>("SellerServicesViewModel")
	const { isAdmin } = useContainerInjection<AuthStore>("AuthStore")
	useUpdateEffect(() => {
		const selectedService = toJS(viewModel.selectedService)
		const newFormData = {
			...structuredClone({
				...selectedService,
				offices: (selectedService.offices?.map(o => ({
					...o,
					calendarAvailability: o.calendarAvailability?.map(a => ({
						...a,
						start: a.start ? dayjs(a.start).toISOString() : undefined,
						end: a.end ? dayjs(a.end).toISOString() : undefined
					}))
				})) ?? []) as ServiceOffice[]
			})
		}
		const formData = { ...newFormData }
		if (locationState?.option !== ServiceOption.DUPLICATE) {
			formData.id = selectedService.id
		} else {
			formData.id = undefined
			formData.offices = formData.offices?.map((o: ServiceOffice) => ({ ...o, id: undefined }))
			// Set session price to -1 if multicurrency set to price 0
			formData.serviceSessions = formData.serviceSessions?.map((s: ServiceSession) => ({
				...s,
				serviceOfficeId: undefined,
				serviceOffice: formData.offices?.find(
					(o: ServiceOffice) => o.sellerOfficeId === s.serviceOffice?.sellerOfficeId
				),
				multicurrency: s.multicurrency?.map(c => ({
					...c,
					price: c.price === 0 && s.price === -1 ? -1 : c.price
				}))
			}))
			setSelectedSellerOfficeId(formData.seller?.offices?.[0]?.id)
		}
		const offices = formData.offices?.map((o: ServiceOffice) => ({
			...o,
			hasReservationLimit: Boolean(
				(o.reservationLimit || o.reservationLimit === 0) && o.reservationLimitInterval
			),
			showAgenda: !!o.serviceCalendar && (o.calendarAvailability?.length ?? 0) > 0
		}))
		setFormData({
			...formData,
			offices
		})
		setOriginalFormData({
			...newFormData,
			offices
		})
		setEditMode(selectedService.id !== undefined)
		setDuplicateMode(locationState?.option === ServiceOption.DUPLICATE)
		if (
			!selectedSellerOfficeId ||
			!formData.offices?.some((o: ServiceOffice) => o.sellerOfficeId === selectedSellerOfficeId)
		) {
			setSelectedSellerOfficeId(formData.offices?.[0]?.sellerOfficeId ?? formData.seller?.offices?.[0]?.id)
		}
	}, [viewModel.selectedService, locationState?.service])

	const initialFormData: Partial<
		Omit<SellerServiceDetail, "offices"> & {
			offices: (ServiceOffice & { hasReservationLimit: boolean; showAgenda: boolean })[]
		}
	> = {
		...locationState?.service,
		countryId: locationState?.service?.countryId || "ES",
		highlighted: locationState?.service?.highlighted || false,
		serviceSessions: locationState?.service?.serviceSessions || [],
		offices: locationState?.service?.offices?.map(o => ({
			...o,
			hasReservationLimit: Boolean(
				(o.reservationLimit || o.reservationLimit === 0) && o.reservationLimitInterval
			),
			showAgenda: !!o.serviceCalendar && (o.calendarAvailability?.length ?? 0) > 0
		}))
	}

	const [formData, setFormData] = useState(initialFormData)
	const [selectedSellerOfficeId, setSelectedSellerOfficeId] = useState<number | undefined>()
	const [originalFormData, setOriginalFormData] = useState(initialFormData)
	const [editMode, setEditMode] = useState(locationState?.service?.id !== undefined)
	const [duplicateMode, setDuplicateMode] = useState(locationState?.option === ServiceOption.DUPLICATE)
	const [modalVisible, setSessionModalVisible] = useState(false)
	const [sessionData, setSessionData] = useState<EditableServiceSession | undefined>(undefined)
	const selectedServiceOffice = formData.offices?.find(o => o.sellerOfficeId === selectedSellerOfficeId)

	useEffect(() => {
		if (!selectedSellerOfficeId) return
		const serviceOffice = formData.offices?.find(o => o.sellerOfficeId === selectedSellerOfficeId)
		if (!serviceOffice) {
			setFormData({
				...formData,
				offices: (formData.offices ?? []).concat([
					{
						sellerOfficeId: selectedSellerOfficeId,
						disabled: true,
						hasReservationLimit: false,
						showAgenda: false
					}
				])
			})
		}
	}, [selectedSellerOfficeId])
	const formModified = () => {
		const formDataSorted = {
			...formData,
			serviceSession: formData?.serviceSessions?.sort((a, b) => (a.id! > b.id! ? 1 : -1))
		}
		const originalFormDataSorted = {
			...originalFormData,
			serviceSession: originalFormData?.serviceSessions?.sort((a, b) => (a.id! > b.id! ? 1 : -1))
		}
		return JSON.stringify(formDataSorted) === JSON.stringify(originalFormDataSorted)
	}

	const onSubmitForm = async () => {
		await formRef.current?.customValidateFields(() => {
			if (!formData.isOnline && !formData.isPresential) {
				throw { message: t("validations.onlineOrFaceToFace") }
			}
			if (isAdmin && (formData?.serviceClusters?.length === 0 || !formData?.serviceClusters)) {
				throw { message: t("validations.cluster") }
			}
			if (formData?.offices?.flatMap(o => o.calendarAvailability ?? []).some(slot => slot.error)) {
				throw { message: t("validations.calendarSlots") }
			}
			if (formData?.offices?.some(o => o.serviceCalendar && !o.serviceCalendar.calendarId)) {
				throw { message: t("validations.calendarId") }
			}
			if (formData?.offices?.some(o => o.serviceCalendar && !o.serviceCalendar.timeZone)) {
				throw { message: t("validations.timeZone") }
			}
			if (formData?.offices?.some(o => o.serviceCalendar && !o.serviceCalendar.duration)) {
				throw { message: t("validations.duration") }
			}
			if (
				formData?.offices?.some(
					o => o.serviceCalendar && !o.serviceCalendar.calendarEnd && !o.serviceCalendar.calendarDaysLength
				)
			) {
				throw { message: t("validations.calendarDaysLength") }
			}
			if (formData?.offices?.some(o => o.serviceCalendar && (o.serviceCalendar.frequency ?? 0) <= 0)) {
				throw { message: t("validations.calendarFrequency") }
			}
			if (!formData.picture || !formData.pictures || formData.pictures.length === 0) {
				throw { message: t("validations.serviceImages") }
			}
		})
		if (editMode && !duplicateMode) {
			const deletedSessions = originalFormData.serviceSessions?.filter(
				session => !formData.serviceSessions?.find(sessionFound => sessionFound.id === session.id)
			)
			await viewModel.updateService(formData, deletedSessions)
		} else {
			const serviceId = await viewModel.createService(formData)
			navigate(".", {
				replace: true,
				state: {
					...locationState,
					service: {
						...toJS(formData),
						id: serviceId,
						offices: formData.offices?.map(o => ({
							...o,
							calendarAvailability: o.calendarAvailability?.map(a => ({
								...a,
								start: dayjs(a.start).toISOString(),
								end: dayjs(a.end).toISOString()
							}))
						}))
					},
					option: ServiceOption.EDIT
				}
			})
		}
	}

	// const onCancelForm = () => {
	//   setFormData(structuredClone(originalFormData))
	// }

	const style = !viewModel.isLoadingDetail
		? {
				card: { backgroundColor: formData.seller ? undefined : "rgba(0,0,0,0.2)" }
		  }
		: undefined

	useEffect(() => {
		viewModel.fetchCountries()
		if (editMode || duplicateMode) {
			viewModel.fetchServiceDetail(formData.id!)
		}
		if (viewModel.sellers.length === 0) {
			viewModel.fetchSellers()
		}
	}, [])

	useEffect(() => {
		if (!isAdmin && viewModel.sellers.length > 0) {
			const seller = viewModel.sellers[0]
			setFormData({
				...formData,
				//@ts-ignore
				seller
			})
		}
	}, [viewModel.sellers])
	const memoizedDataSource =
		useMemo(() => {
			const officeSessions = formData.serviceSessions?.filter(
				s =>
					(s.serviceOfficeId && s.serviceOfficeId === selectedServiceOffice?.id) ||
					s.serviceOffice?.sellerOfficeId === selectedServiceOffice?.sellerOfficeId
			)
			return (
				officeSessions?.length &&
				officeSessions
					.filter(s => s.serviceOfficeId === selectedServiceOffice?.id)
					.map((session, index) => {
						const priceEUR = session?.multicurrency?.find(
							value => value.currencyId === Number(viewModel.currencies[0].id)
						)?.price
						const priceMXN = session?.multicurrency?.find(
							value => value.currencyId === Number(viewModel.currencies[1].id)
						)?.price
						return {
							key: session.id,
							...session,
							priceEUR: priceEUR !== undefined ? priceEUR : "-",
							priceMXN: priceMXN !== undefined ? priceMXN : "-",
							actions: (
								<div style={{ columnGap: 10 }}>
									<DeleteFilled
										style={{ fontSize: "1.5rem", color: "red" }}
										onClick={() => handleDeleteSession(session)}
									/>
									<EditFilled
										style={{ fontSize: "1.5rem", color: "gray" }}
										onClick={() => handleEditSession(session)}
									/>
								</div>
							)
						}
					})
			)
		}, [formData?.serviceSessions, selectedSellerOfficeId]) || []

	const dataSource = memoizedDataSource?.length ? memoizedDataSource : []

	const handleEditSession = (session: ServiceSession) => {
		setSessionData({ ...session, updated: true })
		setSessionModalVisible(true)
	}

	const handleDeleteSession = (session: ServiceSession & any) => {
		const index = session.id ? "id" : "key"
		showConfirm({
			title: tToast("deleteConfirmMessage", { value: "session" }),
			onConfirm: async () => {
				setFormData({
					...formData,
					serviceSessions: formData.serviceSessions?.filter((item: ServiceSession & any) => {
						return item[index] !== session[index]
					})
				})
			},
			type: "danger"
		})
	}

	const handleConfirm = (data: EditableServiceSession & any) => {
		const key = data.id ? "id" : "key"
		const index = formData.serviceSessions?.findIndex(
			(session: ServiceSession & any) => data[key] && session[key] === data[key]
		)
		if (index !== undefined && index !== -1) {
			const newServiceSession = {
				...formData.serviceSessions![index],
				...data,
				updated: !!data?.id,
				serviceOffice: selectedServiceOffice,
				serviceOfficeId: selectedServiceOffice?.id
			}
			const newFormData = [...formData.serviceSessions!]
			newFormData.splice(index, 1, newServiceSession)
			setFormData({
				...formData,
				serviceSessions: newFormData
			})
			viewModel.updateServiceSessionState(data)
		} else {
			const lastKey =
				formData.serviceSessions?.sort((a, b) => (a.key! > b.key! ? 1 : -1))?.[
					formData.serviceSessions?.length - 1
				]?.key || 0
			const newServiceSession: ServiceSession = {
				...data,
				serviceOffice: selectedServiceOffice,
				serviceOfficeId: selectedServiceOffice?.id,
				key: Number(lastKey) + 1
			}
			if (formData?.serviceSessions?.length) {
				setFormData({
					...formData,
					serviceSessions: [...formData.serviceSessions, newServiceSession]
				})
			} else {
				setFormData({ ...formData, serviceSessions: [newServiceSession] })
			}
		}
		setSessionData(undefined)
		setSessionModalVisible(false)
	}

	return (
		<div>
			<MainForm
				onSubmitForm={onSubmitForm}
				formModified={!formModified()}
				initialValues={{ ...formData }}
				ref={formRef}
			>
				{modalContext}
				{toastContext}
				<Row gutter={[24, 0]}>
					<Col xl={24} md={24} xs={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div>
									<h6 className="font-semibold m-0">{t("sellerInfo.cardTitle")}</h6>
								</div>
							}
							bodyStyle={{ padding: "24px" }}
						>
							<FormField.SelectSearch
								label={t("sellerInfo.seller").toString()}
								loading={viewModel.isLoadingSellers || viewModel.isLoadingDetail}
								key={"seller"}
								name={"seller"}
								disabled={
									viewModel.isLoading ||
									viewModel.isLoadingDetail ||
									(editMode && !duplicateMode) ||
									!isAdmin
								}
								placeholder={"Select a seller"}
								style={{ display: "flex" }}
								//@ts-ignore
								value={{ value: formData?.seller?.sellerName, label: formData?.seller?.sellerName }}
								options={viewModel.sellers.map((seller: Seller) => ({
									value: seller.id,
									label: seller.sellerName,
									key: seller.id
								}))}
								onChange={seller => {
									const offices = viewModel.sellers.find(s => s.id === seller.value)?.offices
									if (!selectedSellerOfficeId) {
										setSelectedSellerOfficeId(offices?.[0]?.id)
									}
									setFormData({
										...formData,
										//@ts-ignore
										seller: {
											...formData.seller,
											id: seller.value,
											sellerName: seller?.label,
											offices
										}
									})
								}}
							/>
						</Card>

						{/* SERVICE */}
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div className="flex justify-between items-center">
									<h6 className="font-semibold m-0">{t("serviceInfo.cardTitle")}</h6>
									{editMode && isAdmin ? (
										<div className="flex items-center">
											<p className="mt-0 mr-2" style={{ color: "black" }}>
												{t("status")}
											</p>
											<FormField.Select
												className="mb-0 min-w-[100px]"
												onChange={status => setFormData({ ...formData, status })}
												options={Object.keys(ServiceStatus)
													.filter(key => isNaN(Number(key)))
													.map(s => ({
														id: s,
														value: ServiceStatus[s as keyof typeof ServiceStatus],
														label: ServiceStatus[s as keyof typeof ServiceStatus]
													}))}
												name={"status"}
												value={formData.status ?? ServiceStatus.INACTIVE}
												disabled={viewModel.isLoadingDetail}
												loading={viewModel.isLoadingDetail}
											/>
										</div>
									) : null}
								</div>
							}
							style={style && style.card}
							bodyStyle={{ padding: "24px" }}
						>
							<ServiceInformationFormSection
								editMode={editMode}
								onChangeText={value => {
									setFormData({ ...formData, ...value })
								}}
								data={{
									serviceClusters: formData?.serviceClusters,
									endorsedUser: { id: formData?.endorsedUserId, name: formData?.endorsedName }
								}}
								picture={formData?.picture}
								pictures={formData?.pictures}
								formTextInputProps={{
									isPresential: formData.isPresential,
									hideEditIcon: true,
									disabled: editMode ? false : formData.seller ? false : true
								}}
							/>
						</Card>
						<Row gutter={[24, 0]}>
							<Col md={12} xl={18} xs={24}>
								<Card
									loading={viewModel.isLoadingDetail}
									bordered={false}
									className="criclebox tablespace mb-24 flex-1"
									style={style && { ...style.card }}
									title={<h6 className="font-semibold m-0">{t("selectedOffice")}</h6>}
								>
									<div className="flex flex-col">
										<div
											className="flex justify-between py-4 items-center"
											style={{ paddingLeft: 24, paddingRight: 24 }}
										>
											<FormField.Select
												className="mb-0"
												style={{ flexGrow: 1 }}
												title={t("selectedOffice")}
												onChange={selectedOfficeId => {
													const office = formData.seller?.offices?.find(
														o => o.id === selectedOfficeId
													)
													if (!office) return
													setSelectedSellerOfficeId(office.id)
												}}
												options={formData.seller?.offices?.map(o => {
													return {
														id: o.id,
														value: o.id,
														label: `${o.name} (${
															formData.offices?.find(
																serviceOffice => serviceOffice.sellerOfficeId === o.id
															)?.disabled ?? true
																? t("disabled")
																: t("enabled")
														})`
													}
												})}
												value={selectedSellerOfficeId}
												disabled={viewModel.isLoadingDetail}
												loading={viewModel.isLoadingDetail}
											></FormField.Select>
											<Switch
												containerStyles={{ marginBottom: 0 }}
												title={t("enabled")}
												disabled={!selectedSellerOfficeId}
												checked={
													formData.offices?.find(
														o => o.sellerOfficeId === selectedSellerOfficeId
													)?.disabled === false
												}
												onChange={value => {
													if (!selectedSellerOfficeId) return
													let offices = formData.offices?.slice() ?? []
													const current = formData.offices?.findIndex(
														o => o.sellerOfficeId === selectedSellerOfficeId
													)
													if (current !== undefined && current !== -1) {
														offices[current] = {
															...formData.offices![current],
															disabled: !value
														}
													} else {
														offices = offices.concat({
															sellerOfficeId: selectedSellerOfficeId,
															disabled: !value,
															hasReservationLimit: false,
															showAgenda: false
														})
													}
													setFormData({ ...formData, offices })
												}}
											/>
										</div>
										<Row>
											<Col xl={12} md={12} xs={12} style={{ paddingLeft: 30 }}>
												<div className="flex items-center">
													<p className="m-0">{t("reservationLimit")}</p>
													<Switch
														containerStyles={{ marginBottom: 0 }}
														className="ml-3"
														checked={selectedServiceOffice?.hasReservationLimit}
														onChange={value =>
															setFormData({
																...formData,
																offices: (formData.offices ?? [])
																	.filter(
																		(
																			o: ServiceOffice & {
																				hasReservationLimit: boolean
																			}
																		) => o.sellerOfficeId !== selectedSellerOfficeId
																	)
																	.concat([
																		{
																			...selectedServiceOffice!,
																			hasReservationLimit: value ?? false,
																			reservationLimit: null,
																			reservationLimitInterval: null
																		}
																	])
															})
														}
													/>
												</div>
												<FormField.Input
													inputType={InputType.NUMBER}
													style={{ marginTop: 28 }}
													mandatory={selectedServiceOffice?.hasReservationLimit}
													disabled={!selectedServiceOffice?.hasReservationLimit}
													key={"reservationLimit"}
													placeHolder={t("limit")}
													value={selectedServiceOffice?.reservationLimit}
													onChange={(reservationLimit: string) =>
														setFormData({
															...formData,
															offices: (formData.offices ?? [])
																.filter(
																	o => o.sellerOfficeId !== selectedSellerOfficeId
																)
																.concat([
																	{
																		...selectedServiceOffice!,
																		reservationLimit: reservationLimit
																			? Number(reservationLimit)
																			: null
																	}
																])
														})
													}
												/>
											</Col>
											<Col xl={12} md={12} xs={12}>
												<FormField.Select
													label={t("reservationLimitInterval")}
													mandatory={selectedServiceOffice?.hasReservationLimit}
													disabled={!selectedServiceOffice?.hasReservationLimit}
													value={selectedServiceOffice?.reservationLimitInterval}
													onChange={reservationLimitInterval => {
														setFormData({
															...formData,
															offices: (formData.offices ?? [])
																.filter(
																	o => o.sellerOfficeId !== selectedSellerOfficeId
																)
																.concat([
																	{
																		...selectedServiceOffice!,
																		reservationLimitInterval
																	}
																])
														})
													}}
													options={[
														{ label: t("daily"), value: ReservationLimitInterval.DAILY },
														{ label: t("weekly"), value: ReservationLimitInterval.WEEKLY },
														{ label: t("monthly"), value: ReservationLimitInterval.MONTHLY }
													]}
												/>
											</Col>
										</Row>
									</div>
								</Card>
								<Card
									loading={viewModel.isLoadingDetail}
									bordered={false}
									className="criclebox tablespace mb-24 flex-1"
									title={
										<div className="flex gap-4 items-center">
											<h6 className="font-semibold m-0">{t("availability.cardTitle")}</h6>
											<Switch
												checked={!!selectedSellerOfficeId && selectedServiceOffice?.showAgenda}
												onChange={value => {
													setFormData({
														...formData,
														offices: (formData.offices ?? [])
															.filter(o => o.sellerOfficeId !== selectedSellerOfficeId)
															.concat([
																{
																	...selectedServiceOffice!,
																	showAgenda: value,
																	serviceCalendar:
																		value && !selectedServiceOffice?.serviceCalendar
																			? {
																					frequency: 1,
																					slots: 1,
																					calendarDaysLength: 90,
																					serviceOfficeId:
																						selectedServiceOffice!.id!,
																					calendarStart: new Date()
																			  }
																			: selectedServiceOffice?.serviceCalendar
																}
															])
													})
													!value
														? serviceAvailavitityFormRef.current?.closeCollapseMenu()
														: serviceAvailavitityFormRef.current?.enableCollapseMenu()
												}}
												disabled={!selectedSellerOfficeId}
												containerStyles={{ marginBottom: 0 }}
											/>
										</div>
									}
									bodyStyle={{
										paddingRight: "24px",
										paddingBottom: "24px",
										paddingLeft: "24px"
									}}
									style={style && { ...style.card }}
								>
									{selectedServiceOffice && (
										<ServiceAvailabilityFormSection
											ref={serviceAvailavitityFormRef}
											calendarSchedule={selectedServiceOffice.calendarAvailability}
											calendarData={selectedServiceOffice.serviceCalendar}
											disabled={
												formData.seller === undefined || !selectedServiceOffice?.showAgenda
											}
											onChange={values => {
												setFormData({
													...formData,
													offices: (formData.offices ?? [])
														.filter(o => o.sellerOfficeId !== selectedSellerOfficeId)
														.concat([{ ...selectedServiceOffice, ...values }])
												})
											}}
										/>
									)}
								</Card>
							</Col>
							<Col md={12} xl={6} xs={24}>
								<Card
									bordered={false}
									className="criclebox tablespace mb-24"
									title={
										<div>
											<h6 className="font-semibold m-0">{t("configInfo.cardTitle")}</h6>
										</div>
									}
									bodyStyle={{ padding: "24px", paddingBottom: "64px" }}
									style={style?.card}
								>
									<ServiceConfigurationFormSection
										values={formData}
										onChange={value => setFormData({ ...formData, ...value })}
										disabled={!formData.seller}
									/>
								</Card>
							</Col>
						</Row>
						<Row gutter={[24, 0]}>
							<Col xl={24} md={24} xs={24}>
								<Card
									bordered={false}
									className="criclebox tablespace mb-24"
									title={
										<div style={{ display: "flex", alignItems: "center" }}>
											<h6 className="font-semibold m-0">{t("sessionsInfo.cardTitle")}</h6>
											<Button
												disabled={!formData.seller}
												onClick={() => {
													setSessionData(undefined)
													setSessionModalVisible(true)
												}}
												type="primary"
												style={{
													marginLeft: "1.5vw",
													height: "34px",
													display: "flex",
													alignItems: "center"
												}}
											>
												{t("sessionsInfo.addNew")}
											</Button>
										</div>
									}
									style={style && style.card}
								>
									<DataTable
										loading={viewModel.isLoadingDetail}
										renderCustomLoadingComponent={() => <DataTableRowsSkeleton />}
										disabled={!formData.seller}
										columns={TABLE_COLUMNS()}
										dataSource={dataSource}
									/>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</MainForm>

			<ServiceSessionModal
				data={sessionData}
				currencies={viewModel.currencies}
				visible={modalVisible}
				onConfirm={handleConfirm}
				onCancel={() => {
					setSessionData(undefined)
					setSessionModalVisible(false)
				}}
			/>
		</div>
	)
}

export default observer(SellerServicesFormView)
