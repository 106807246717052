interface ContructorParams {
	calendarId: string | null
	duration: string
	timeZone: string
	serviceOfficeId: number
	slots: number
	calendarStart: Date
	calendarEnd?: Date | null
	calendarDaysLength?: number | null
	frequency: number
}

export class CalendarData {
	public calendarId?: string | null
	public duration?: string
	public timeZone?: string
	public serviceOfficeId: number
	public slots: number
	public calendarStart: Date
	public calendarEnd?: Date | null
	public calendarDaysLength?: number | null
	public frequency: number

	constructor(params: ContructorParams) {
		this.calendarId = params.calendarId
		this.duration = params.duration
		this.timeZone = params.timeZone
		this.serviceOfficeId = params.serviceOfficeId
		this.slots = params.slots
		this.calendarStart = params.calendarStart
		this.calendarEnd = params.calendarEnd
		this.calendarDaysLength = params.calendarDaysLength
		this.frequency = params.frequency
	}
}
