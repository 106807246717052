import { ServiceOffice } from "../../domain/entities/ServiceOffice"
import { ServiceSessionTranslation } from "../../domain/entities/ServiceSessionTranslation"
import { Multicurrency } from "../dto/MultiCurrency.dto"
import { ServiceSession } from "./../../domain/entities/ServiceSesssion"
import { ServiceOfficeModel } from "./ServiceOfficeModel"
import { ServiceSessionTranslationModel } from "./ServiceSessionTranslationModel"

interface ConstructorParams {
	id?: string
	title: string
	description?: string
	subtitle?: string
	multicurrency?: Multicurrency[]
	price: number
	outprice: number
	position?: number
	serviceSessionTranslations?: ServiceSessionTranslationModel[]
	serviceOfficeId?: number
}

export class ServiceSessionModel {
	public id?: string
	public title: string
	public description?: string
	public subtitle?: string
	public multicurrency?: Multicurrency[]
	public price: number
	public outprice: number
	public position?: number
	public serviceSessionTranslations?: ServiceSessionTranslationModel[]
	public serviceOfficeId?: number

	constructor(params: ConstructorParams) {
		this.id = params.id
		this.multicurrency = params.multicurrency
		this.title = params.title
		this.description = params.description
		this.subtitle = params.subtitle
		this.price = params.price
		this.outprice = params.outprice
		this.position = params.position
		this.serviceSessionTranslations = params.serviceSessionTranslations
		this.serviceOfficeId = params.serviceOfficeId
	}

	public get title_en() {
		return this.serviceSessionTranslations?.find(t => t.locale === "en-us")?.title
	}
	public get title_ca() {
		return this.serviceSessionTranslations?.find(t => t.locale === "ca-es")?.title
	}
	public get title_es() {
		return this.serviceSessionTranslations?.find(t => t.locale === "es-es")?.title
	}
	public get description_es() {
		return this.serviceSessionTranslations?.find(t => t.locale === "es-es")?.description
	}
	public get description_en() {
		return this.serviceSessionTranslations?.find(t => t.locale === "en-us")?.description
	}
	public get description_ca() {
		return this.serviceSessionTranslations?.find(t => t.locale === "ca-es")?.description
	}
	public get subtitle_es() {
		return this.serviceSessionTranslations?.find(t => t.locale === "es-es")?.subtitle
	}
	public get subtitle_en() {
		return this.serviceSessionTranslations?.find(t => t.locale === "en-us")?.subtitle
	}
	public get subtitle_ca() {
		return this.serviceSessionTranslations?.find(t => t.locale === "ca-es")?.subtitle
	}

	static fromRawJson = (rawJson: any): ServiceSessionModel => {
		const {
			id,
			title,
			description,
			subtitle,
			multicurrency,
			price,
			outprice,
			position,
			serviceSessionTranslations,
			serviceOfficeId
		} = rawJson

		return new ServiceSessionModel({
			id,
			title,
			multicurrency,
			description,
			subtitle,
			price,
			outprice,
			position,
			serviceSessionTranslations: serviceSessionTranslations.map((t: any) =>
				ServiceSessionTranslationModel.fromRawJson(t)
			),
			serviceOfficeId
		})
	}

	static fromDomain = (serviceSession: ServiceSession): ServiceSessionModel => {
		const serviceSessionTranslations = [
			new ServiceSessionTranslation({
				locale: "es-es",
				title: serviceSession.title,
				description: serviceSession.description,
				subtitle: serviceSession.subtitle,
				serviceSessionId: serviceSession.id!
			})
		]
		if (serviceSession.title_en || serviceSession.description_en || serviceSession.subtitle_en) {
			serviceSessionTranslations.push(ServiceSessionModel.getTranslation(serviceSession, "en-us", "en"))
		}
		if (serviceSession.title_ca || serviceSession.description_ca || serviceSession.subtitle_ca) {
			serviceSessionTranslations.push(ServiceSessionModel.getTranslation(serviceSession, "ca-es", "ca"))
		}
		return new ServiceSessionModel({
			id: serviceSession.id,
			title: serviceSession.title,
			position: Number(serviceSession.position),
			multicurrency: serviceSession.multicurrency?.map(currency => {
				return {
					currencyId: currency.currencyId,
					price: currency.price || 0,
					outprice: Number(currency.outprice) || 0,
					sellerPrice: currency.sellerPrice ? Number(currency.sellerPrice) : undefined
				}
			}),
			description: serviceSession.description,
			subtitle: serviceSession.subtitle,
			price: serviceSession.price ?? 0,
			outprice: serviceSession.outprice,
			serviceSessionTranslations: serviceSessionTranslations.map(t =>
				ServiceSessionTranslationModel.fromDomain(t)
			),
			serviceOfficeId: serviceSession.serviceOfficeId
		})
	}

	private static getTranslation(
		session: ServiceSession,
		locale: string,
		lang: "en" | "ca"
	): ServiceSessionTranslation {
		return new ServiceSessionTranslation({
			locale,
			title: session[`title_${lang}`],
			description: session[`description_${lang}`],
			subtitle: session[`subtitle_${lang}`],
			serviceSessionId: session.id!
		})
	}
}

declare module "./ServiceSessionModel" {
	interface ServiceSessionModel {
		toDomain(serviceOffice?: ServiceOfficeModel): ServiceSession
	}
}

ServiceSessionModel.prototype.toDomain = function (serviceOffice?: ServiceOfficeModel): ServiceSession {
	const data = this
	return new ServiceSession({
		...data,
		position: data.position?.toString(),
		description_en: data.description_en,
		description_ca: data.description_ca,
		subtitle_en: data.subtitle_en,
		subtitle_ca: data.subtitle_ca,
		title_en: data.title_en,
		title_ca: data.title_ca,
		serviceOffice: serviceOffice?.toDomain()
	})
}
