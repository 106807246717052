import { Link } from "react-router-dom"
import TABLE_COLUMNS from "../const/SERVICES_TABLE_COLUMNS"
import { Tag, Tooltip, Typography } from "antd"
import { CopyFilled, EditFilled } from "@ant-design/icons"
import { observer } from "mobx-react"
import { RoutePaths } from "../../../navigation/RoutePaths.enum"
import { toJS } from "mobx"
import { SellerService } from "../../../../domain/entities/SellerService"
import DataTable from "../../../components/DataTable/DataTable"
import { SellerServicesViewModel } from "../SellerServicesViewModel"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import { ServiceStatus } from "../../../../domain/enum/serviceStatus.enum"
import { CustomBasicSelector } from "../../../components/CustomSelectors/CustomBasicSelector"
import { ServiceOption } from "../../../service/ServiceOption"
import { AuthStore } from "../../../stores/AuthStore"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"

interface SellerTableProps {
	data: Partial<SellerService>[]
	loading?: boolean
	isFetching?: boolean
	onChangePage?: (page: number, pageSize: number, lastPageFetched: number) => void
}

const extractClusters = (data: Partial<SellerService>[]) => {
	const allClusters = data
		.flatMap(s => s.clusters)
		.filter(c => !!c?.clusterName)
		.reduce(
			(clusters, c) => ({ ...clusters, [c!.clusterId]: c!.clusterName }),
			{} as { [clusterId: number]: string }
		)

	return Object.entries(allClusters).map(([clusterId, clusterName]) => ({ clusterId, clusterName }))
}

const SellerServicesTable = ({ data, loading, isFetching, onChangePage }: SellerTableProps) => {
	const viewModel = useContainerInjection<SellerServicesViewModel>("SellerServicesViewModel")
	const { isAdmin } = useContainerInjection<AuthStore>("AuthStore")
	const { t } = useTranslation("common")
	const [clusters, setClusters] = useState<{ clusterId: string; clusterName: string }[]>([])

	useEffect(() => {
		setClusters(extractClusters(data))
	}, [data])

	return (
		<div className="table-responsive">
			<DataTable
				onChangePage={(page, pageSize, lastPageFetched) => {
					viewModel.setTableConfig({ pageSize })
					onChangePage && onChangePage(page, pageSize, lastPageFetched)
				}}
				renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
				loading={loading}
				tableConfig={{
					sort: viewModel.tableConfig.sort,
					pageSize: viewModel.tableConfig.pageSize
				}}
				onChange={data => {
					data.sorter &&
						viewModel.setTableConfig({ sort: { order: data.sorter.order, field: data.sorter.field } })
				}}
				isFetching={isFetching}
				columns={TABLE_COLUMNS(isAdmin)}
				columnFilters={{
					user: Array.from(new Set(data.filter(s => !!s.name).map(s => s.name!))).map(name => ({
						id: name,
						text: name,
						value: name
					})),
					status: Object.keys(ServiceStatus).map(s => ({
						id: s,
						text: ServiceStatus[s as keyof typeof ServiceStatus],
						value: ServiceStatus[s as keyof typeof ServiceStatus]
					})),
					clusters: clusters.map(c => ({
						id: c!.clusterId,
						text: `${c!.clusterId} - ${c!.clusterName}`,
						value: `${c!.clusterId} - ${c!.clusterName}`
					})),
					crossSelling: [
						{
							text: t("yes"),
							value: t("yes")
						},
						{
							text: t("no"),
							value: t("no")
						}
					]
				}}
				dataSource={data.map((service, idx) => ({
					key: service.id,
					id: service.id,
					weight: service.weight,
					user: service.name,
					service: (
						<>
							<div className="avatar-info">
								<Typography.Text>{service.title}</Typography.Text>
							</div>
						</>
					),
					status: isAdmin && (
						<CustomBasicSelector
							className="mb-0 min-w-[100px]"
							onChange={status => viewModel.updateServiceStatus(service.id!, status)}
							options={Object.keys(ServiceStatus)
								.filter(key => isNaN(Number(key)))
								.map(s => ({
									id: s,
									value: ServiceStatus[s as keyof typeof ServiceStatus],
									label: ServiceStatus[s as keyof typeof ServiceStatus]
								}))}
							value={service.status}
							loading={viewModel.isLoadingDetail}
							disabled={viewModel.isLoadingDetail}
						/>
					),
					crossSelling: service.crossSelling ? t("yes") : t("no"),
					clusters: (() => {
						const clusters = service.clusters || []
						return (
							<div
								style={{
									flexWrap: "wrap",
									display: "flex"
								}}
							>
								{clusters?.length
									? clusters?.map((c, idx) => (
											<Tag
												style={{ marginBottom: 3 }}
												key={idx}
												color={
													c.clusterName.length > 18
														? "geekblue"
														: c.clusterName.length > 12
														? "cyan"
														: "blue"
												}
											>
												{`${c.clusterId} - ${c.clusterName}`}
											</Tag>
									  ))
									: null}
							</div>
						)
					})(),
					editButton: (
						<div className="ant-employed">
							<Link
								to={RoutePaths.SELLERS_SERVICES_ADD_NEW}
								state={{ service: toJS(service), option: ServiceOption.EDIT }}
							>
								<Tooltip title={"Edit"}>
									<EditFilled />
								</Tooltip>
							</Link>
						</div>
					),
					duplicateButton: (
						<div className="ant-employed">
							<Link
								to={RoutePaths.SELLERS_SERVICES_ADD_NEW}
								state={{ service: toJS(service), option: ServiceOption.DUPLICATE }}
							>
								<Tooltip title={"Duplicate"}>
									<CopyFilled />
								</Tooltip>
							</Link>
						</div>
					)
				}))}
			/>
		</div>
	)
}

export default observer(SellerServicesTable)
