import { ServiceStatus } from "../enum/serviceStatus.enum"

interface ContructorParams {
	contact: string
	contact_telf: string
	email: string
	id: number
	name: string
	title: string
	weight: number
	status: ServiceStatus
	crossSelling: boolean
	clusters: { clusterId: number; clusterName: string }[]
}

export class SellerService {
	public contact: string
	public contact_telf: string
	public email: string
	public id: number
	public name: string
	public title: string
	public weight: number
	public status: ServiceStatus
	public crossSelling: boolean
	public clusters: { clusterId: number; clusterName: string }[]

	constructor(params: ContructorParams) {
		this.contact = params.contact
		this.contact_telf = params.contact_telf
		this.email = params.email
		this.id = params.id
		this.name = params.name
		this.title = params.title
		this.weight = params.weight
		this.status = params.status
		this.crossSelling = params.crossSelling
		this.clusters = params.clusters
	}
}
