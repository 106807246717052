import { Checkbox, Col, Collapse, DatePicker, Form, Row } from "antd"
import Switch from "../../../components/Switch/Switch"
import WeekDaysCheckGroup from "./WeekDaysCheckGroup"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import { SellerServiceDetail } from "../../../../domain/entities/SellerServiceDetail"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { SellerServicesViewModel } from "../SellerServicesViewModel"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { WeekDays } from "../../../../domain/entities/WeekDays"
import Divider from "../../../styledComponents/CustomDivider/Divider"
import { FormField } from "../../../components/Form/FormField"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { CalendarSchedule } from "./CalendarSchedule"
import { CalendarAvailability } from "../../../../domain/entities/CalendarAvailability"
import { RightOutlined } from "@ant-design/icons"
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { CollapsibleType } from "antd/lib/collapse/CollapsePanel"
import { observer } from "mobx-react"
import { CalendarData } from "../../../../domain/entities/CalendarData"
import dayjs from "dayjs"

interface ServiceAvailabilityFormSectionProps {
	calendarSchedule?: CalendarAvailability[]
	calendarData?: CalendarData
	onChange: ({ calendarAvailability }: any) => void
	disabled?: boolean
}

const DEFAULT_COLLAPSE_KEYS = ["schedule", "calendar"]

export interface ServiceAvailabilityFormRef {
	closeCollapseMenu: () => void
	enableCollapseMenu: () => void
}

const ServiceAvailabilityFormSection = forwardRef(
	(
		{ calendarSchedule, calendarData, onChange, disabled }: ServiceAvailabilityFormSectionProps,
		ref: Ref<ServiceAvailabilityFormRef>
	) => {
		const { t } = useTranslation("services", { keyPrefix: "form" })
		const viewModel = useContainerInjection<SellerServicesViewModel>("SellerServicesViewModel")
		const [collapseKeys, setCollapseKeys] = useState<string[] | string>(!disabled ? DEFAULT_COLLAPSE_KEYS : [])
		const [collapsible, setCollapsible] = useState<CollapsibleType | undefined>(disabled ? "disabled" : undefined)
		const [isDynamicEnd, setDynamicEnd] = useState(!calendarData?.calendarEnd)

		useImperativeHandle(ref, () => ({
			closeCollapseMenu: () => {
				setCollapseKeys([])
				setCollapsible("disabled")
			},
			enableCollapseMenu: () => {
				setCollapseKeys(DEFAULT_COLLAPSE_KEYS)
				setCollapsible(undefined)
			}
		}))

		useEffect(() => {
			setCollapsible(disabled ? "disabled" : undefined)
			setCollapseKeys(!disabled ? DEFAULT_COLLAPSE_KEYS : [])
		}, [disabled])
		useEffect(() => {
			setDynamicEnd(!calendarData?.calendarEnd)
		}, [calendarData?.serviceOfficeId])
		const combineRanges = (selectedRange: CheckboxValueType[]): string => {
			const selectedRangeIndexs = selectedRange
				.map(value => {
					return value.toString().indexOf("1")
				})
				.filter(value => value !== -1)

			const combinedRange = selectedRangeIndexs.reduce((acc, value) => {
				const newAcc = acc.split("")
				newAcc[value] = "1"
				return newAcc.join("")
			}, "000")
			viewModel.setDefaultAgendaHourRange(combinedRange)
			return combinedRange
		}

		const renderTimeZoneComponent = () => {
			if (!Intl.supportedValuesOf("timeZone").length) {
				console.warn("Your browser does not support Intl.supportedValuesOf().")
				return (
					<FormField.Input
						key={"timeZone"}
						mandatory={calendarData?.calendarId ? true : false}
						label={t("availability.timeZone")}
						value={calendarData?.timeZone}
						onChange={timeZone => onChange && onChange({ serviceCalendar: { ...calendarData, timeZone } })}
					/>
				)
			} else {
				return (
					<FormField.SelectSearch
						key={"timeZone"}
						label={t("availability.timeZone").toString()}
						mandatory={calendarData?.calendarId ? true : false}
						placeholder={t("availability.timeZone").toString()}
						value={calendarData?.timeZone}
						options={Intl.supportedValuesOf("timeZone").map((value: string) => ({ value, label: value }))}
						onChange={timeZone =>
							//@ts-ignore
							onChange && onChange({ serviceCalendar: { ...calendarData, timeZone: timeZone.value } })
						}
					/>
				)
			}
		}
		return (
			<>
				<Collapse
					expandIconPosition="right"
					activeKey={collapseKeys}
					expandIcon={({ isActive }) => {
						const animatedClassName = isActive ? "rotate-90" : ""
						return (
							<div className={`absolute top-[3.1rem] transition-all ${animatedClassName}`}>
								{<RightOutlined />}
							</div>
						)
					}}
					onChange={key => setCollapseKeys(key)}
					collapsible={collapsible}
					ghost
					items={[
						{
							key: "calendar",
							label: (
								<div>
									<Divider title="Calendar" disabled={disabled} />
								</div>
							),
							children: (
								<>
									<Row gutter={[24, 0]}>
										<Col xl={12} md={24} xs={24}>
											<FormField.Input
												key={"calendarId"}
												label={t("availability.calendarId")}
												value={calendarData?.calendarId}
												onChange={calendarId =>
													onChange &&
													onChange({ serviceCalendar: { ...calendarData, calendarId } })
												}
											/>
										</Col>
										<Col xl={12} md={24} xs={24}>
											<FormField.Input
												key={"duration"}
												mandatory={calendarData?.calendarId ? true : false}
												label={t("availability.sessionTime")}
												value={calendarData?.duration}
												inputType={InputType.NUMBER}
												onChange={duration =>
													onChange &&
													onChange({ serviceCalendar: { ...calendarData, duration } })
												}
												customFieldValidation={
													calendarData?.duration &&
													(Number(calendarData.duration) < 15 ||
														Number(calendarData.duration) > 1440)
														? {
																status: "error",
																message: t(
																	"availability.validations.sessionTimeValidation"
																).toString()
														  }
														: { status: "", message: "" }
												}
											/>
										</Col>
									</Row>
									<Row gutter={[24, 0]}>
										<Col xl={12} md={24} xs={24}>
											{renderTimeZoneComponent()}
										</Col>
										<Col xl={12} md={24} xs={24}>
											<FormField.Input
												key={"slots"}
												mandatory={calendarData?.calendarId ? true : false}
												label={t("availability.slots")}
												value={calendarData?.slots}
												inputType={InputType.NUMBER}
												onChange={slots =>
													onChange &&
													onChange({
														serviceCalendar: { ...calendarData, slots: Number(slots) }
													})
												}
												customFieldValidation={
													calendarData?.slots && Number(calendarData.slots) < 0
														? {
																status: "error",
																message: t(
																	"availability.validations.slotsValidation"
																).toString()
														  }
														: { status: "", message: "" }
												}
											/>
										</Col>
									</Row>
									<Row gutter={[24, 0]}>
										<Col xl={12} md={24} xs={24}>
											<FormField.DatePicker
												key={"calendarStart"}
												defaultValue={dayjs(new Date())}
												value={dayjs(calendarData?.calendarStart ?? new Date())}
												mandatory={calendarData?.calendarId ? true : false}
												label={t("availability.calendarStart")}
												placeHolder={t("availability.calendarStart")}
												onChange={calendarStart =>
													onChange &&
													onChange({
														serviceCalendar: {
															...calendarData,
															calendarStart: calendarStart?.toDate()
														}
													})
												}
											/>
											<FormField.Input
												key={"frequency"}
												mandatory={calendarData?.calendarId ? true : false}
												label={t("availability.calendarFrequency")}
												value={calendarData?.frequency}
												inputType={InputType.NUMBER}
												onChange={frequency =>
													onChange &&
													onChange({
														serviceCalendar: {
															...calendarData,
															frequency: Number(frequency ?? 0)
														}
													})
												}
												customFieldValidation={
													!calendarData?.frequency || Number(calendarData.frequency) < 0
														? {
																status: "error",
																message: t(
																	"availability.validations.calendarFrequencyValidation"
																).toString()
														  }
														: { status: "", message: "" }
												}
											/>
										</Col>
										<Col xl={12} md={24} xs={24}>
											{isDynamicEnd ? (
												<FormField.Input
													label={`${t("availability.calendarEnd")} (${t(
														"availability.days"
													)})`}
													key={"calendarDaysLength"}
													mandatory={isDynamicEnd && calendarData?.calendarId ? true : false}
													placeHolder={t("availability.calendarDaysLength")}
													value={calendarData?.calendarDaysLength}
													inputType={InputType.NUMBER}
													onChange={value =>
														onChange &&
														onChange({
															serviceCalendar: {
																...calendarData,
																calendarDaysLength: value ? Number(value) : undefined,
																calendarEnd: null
															}
														})
													}
													customFieldValidation={
														calendarData?.calendarDaysLength &&
														Number(calendarData.calendarDaysLength) < 1
															? {
																	status: "error",
																	message: t(
																		"availability.validations.calendarDaysLengthValidation"
																	).toString()
															  }
															: { status: "", message: "" }
													}
												/>
											) : (
												<FormField.DatePicker
													label={t("availability.calendarEnd")}
													key={"calendarEnd"}
													value={
														calendarData?.calendarEnd
															? dayjs(calendarData.calendarEnd)
															: undefined
													}
													mandatory={!isDynamicEnd && calendarData?.calendarId ? true : false}
													className="w-full"
													placeHolder={t("availability.calendarEnd")}
													onChange={calendarEnd =>
														onChange &&
														onChange({
															serviceCalendar: {
																...calendarData,
																calendarEnd: calendarEnd?.toDate(),
																calendarDaysLength: null
															}
														})
													}
												/>
											)}
											<div style={{ paddingRight: "3rem", transform: "translateY(-1rem)" }}>
												<Switch
													title={t("availability.dynamicEnd")}
													checked={isDynamicEnd}
													onChange={dynamicEnd => {
														if (dynamicEnd) {
															onChange &&
																onChange({
																	serviceCalendar: {
																		...calendarData,
																		calendarEnd: null
																	}
																})
														} else {
															onChange &&
																onChange({
																	serviceCalendar: {
																		...calendarData,
																		calendarDaysLength: null
																	}
																})
														}
														setDynamicEnd(dynamicEnd)
													}}
												/>
											</div>
										</Col>
									</Row>
									<Row gutter={[24, 0]} className="mt-2">
										<Col>
											<CalendarSchedule
												disabled={disabled}
												values={CalendarAvailability.transformToCalendarScheduleObject(
													calendarSchedule
												)}
												onChange={values => {
													onChange &&
														onChange({
															calendarAvailability:
																CalendarAvailability.transformToCalendarScheduleArray(
																	values
																)
														})
												}}
												sessionDuration={
													calendarData?.duration ? Number(calendarData.duration) : undefined
												}
												//@ts-ignore
												hoursInterval={
													calendarData?.duration
														? Math.floor(Number(calendarData.duration) / 60)
														: undefined
												}
												//@ts-ignore
												minutesInterval={
													calendarData?.duration &&
													(60 % Number(calendarData?.duration) == 0 ||
														Math.floor(Number(calendarData.duration) % 60) == 0)
														? Math.floor(Number(calendarData.duration) % 60)
														: 15
												}
											/>
										</Col>
									</Row>
								</>
							)
						}
					]}
				/>
			</>
		)
	}
)

export default observer(ServiceAvailabilityFormSection)
