import { CalendarData } from "../../domain/entities/CalendarData"

export interface ContructorParams {
	calendarId: string | null
	duration: number
	timeZone: string
	serviceOfficeId: number
	slots: number
	calendarStart: Date
	calendarEnd?: Date | null
	calendarDaysLength?: number | null
	frequency: number
}

export class CalendarDataModel {
	public calendarId: string | null
	public duration: number
	public timeZone: string
	public serviceOfficeId: number
	public slots: number
	public calendarStart: Date
	public calendarEnd?: Date | null
	public calendarDaysLength?: number | null
	public frequency: number

	constructor(params: ContructorParams) {
		this.calendarId = params.calendarId
		this.duration = params.duration
		this.timeZone = params.timeZone
		this.serviceOfficeId = params.serviceOfficeId
		this.slots = params.slots
		this.calendarStart = params.calendarStart
		this.calendarEnd = params.calendarEnd
		this.calendarDaysLength = params.calendarDaysLength
		this.frequency = params.frequency
	}

	public static fromRawJson = (rawJson: any): CalendarDataModel => {
		return new CalendarDataModel({
			...rawJson
		})
	}

	public static fromDomain = (calendarData: CalendarData): CalendarDataModel => {
		return new CalendarDataModel({
			calendarId: calendarData.calendarId || null,
			duration: Number(calendarData.duration ?? 60),
			timeZone: calendarData.timeZone!,
			serviceOfficeId: calendarData.serviceOfficeId,
			slots: calendarData.slots,
			calendarStart: calendarData.calendarStart,
			calendarEnd: calendarData.calendarEnd,
			calendarDaysLength: calendarData.calendarDaysLength,
			frequency: calendarData.frequency
		})
	}
}

declare module "./CalendarDataModel" {
	interface CalendarDataModel {
		toDomain(): CalendarData
	}
}

CalendarDataModel.prototype.toDomain = function (): CalendarData {
	const data = this
	return new CalendarData({
		calendarId: data.calendarId,
		duration: String(data.duration),
		timeZone: data.timeZone,
		serviceOfficeId: data.serviceOfficeId,
		slots: data.slots,
		calendarStart: data.calendarStart,
		calendarEnd: data.calendarEnd,
		calendarDaysLength: data.calendarDaysLength,
		frequency: data.frequency
	})
}
